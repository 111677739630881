import {BrowserModule} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import {CookieService} from 'ngx-cookie-service';
import {ToastService} from '../assets/services/toast.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {InvalidCredentialComponent} from './invalid-credential/invalid-credential.component';
import {DashboardOptionCardComponent} from './dashboard/dashboard-option-card/dashboard-option-card.component';
import {FooterModule} from './footer/footer.module';
import {SessionStorageService} from '../assets/services/session-storage.service';
import {environment} from '../environments/environment';
import {ToastrModule} from 'ngx-toastr';
import { HelpDeskModalComponent } from './dashboard/help-desk-modal/help-desk-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    InvalidCredentialComponent,
    DashboardOptionCardComponent,
    HelpDeskModalComponent,
  ],
  imports: [
    FooterModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ToasterModule,
    NgbModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule, MatListModule,
    MatMenuModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule, MatRadioModule,
    MatTabsModule, MatDialogModule,
  ],
  providers: [ToastService, ToasterService, CookieService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    SessionStorageService.forRoot(environment.product_name, true);
  }
}
