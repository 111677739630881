import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DataService} from '../../assets/services/data.service';
import {Router} from '@angular/router';
import {ToastService} from '../../assets/services/toast.service';
import {CookieService} from 'ngx-cookie-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isShowMsg: boolean = false;

  constructor(
    private request: DataService,
    protected router: Router,
    public cookie: CookieService,
    private modalService: NgbModal, private toast: ToastService
  ) {
  }

  modalReference: any;
  loading: boolean = false;
  user: any = {};
  updateUser: any = {};
  submitted: boolean = false;
  @ViewChild('pageBlocker') pageBlocker: ElementRef;

  ngOnInit() {
  }

  login(): void {
    this.submitted = true;
    this.request.login(this.user).subscribe(
      async (response) => {
        this.submitted = false;
        this.cookie.set('dashboardUsername', response['data']['firstname'], null, '/');
        this.cookie.set('user_type', response['data']['user_type'], null, '/');
        this.cookie.set('sessionID', response['data']['authtoken'], null, '/');
        this.cookie.set('dashboardUserEmail', response['data']['email'], null, '/');
        this.cookie.set('role', response['data']['roles'], null, '/');
        this.cookie.set('id', response['data']['id'], null, '/');
        this.cookie.set('businessrole', response['data']['businessrole'], null, '/');
        this.cookie.set('business', response['data']['business'], null, '/');

        if (response['data']['phone'] == null || response['data']['email'] == null) {
          this.updateUser['email'] = response['data']['email'];
          this.updateUser['phone'] = response['data']['phone'];
          this.modalReference = this.modalService.open(this.pageBlocker, {size: 'lg', backdrop: 'static'});
          document.getElementsByClassName('page-blocker')[0].parentNode.parentElement.classList.add('page-blocker-modal');
          document.getElementsByClassName('page-blocker')[0].parentElement.classList.add('full-page-width');

        } else {
          await this.router.navigate(['home']);
        }
      },
      error => {
        this.submitted = false;
      }
    );
  }


  userUpdate(user) {
    this.loading = true;
    let id = parseInt(this.cookie.get('id'));
    let payload = {
      'email': this.updateUser['email'],
      'phone': this.updateUser['phone']
    };
    this.request.updateUser(id, payload).subscribe((response) => {
      this.loading = false;
      this.toast.showToast({
        type: 'success',
        title: '',
        body: response['message']
      });
      this.back();
    }, (error) => {
      this.loading = false;
    });
  }

  back() {
    this.modalReference.close();
    this.user = {};
    this.router.navigate(['/login']);

  }

}
