import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {DataService} from '../../assets/services/data.service';
import {CookieService} from 'ngx-cookie-service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastService} from '../../assets/services/toast.service';
import {environment} from '../../environments/environment';
import {UtilityServiceService} from '../../assets/services/utility-service.service';
import {SessionStorageService} from '../../assets/services/session-storage.service';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiResponse, WalletData} from '../../assets/interfaces/general';
import { MatDialog } from '@angular/material/dialog';

import { HelpDeskModalComponent } from './help-desk-modal/help-desk-modal.component';
import moment from 'moment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading: boolean = false;
  kitStatus = [];
  authToken: null;
  warranty: {
    tvSize: any, premium: any; serialNumber: string;
    name: string;
    address: string;
    city: string;
    state: string;
    pin: string;
    phone: string;
    email: string;
    activation_code: string;
    isActivation_code: string;
  } = {
    premium: null,
    serialNumber: '',
    name: '',
    address: '',
    city: '',
    state: '',
    pin: '',
    phone: '',
    email: '',
    activation_code: '',
    isActivation_code: '',
    tvSize: {},
  };
  mobileSerialNo: string;
  showError: boolean = false;
  public completed = false;
  isMobile = false;
  category = null;
  data: FileList;
  isLaptop: boolean = false;
  isPurifier: boolean = false;
  walletDisabled = true;

  capitalize(evt) {
    this.warranty.serialNumber = evt.target.value.toUpperCase().replace(/[\t\r\n ]/g, '');
  }

  processSerialNumber() {

    this.completed = true;
    const serialNumber = this.warranty.serialNumber.replace(/[\t\r\n ]/g, '');
    if (serialNumber) {
      this.loading = true;
      this.mobileSerialNo = null;
      this.isLaptop = false;
      this.isMobile = false;
      this.isPurifier = false;

      this.request.getProductName({
        serialnumber: serialNumber
      }).subscribe((res) => {
        // setting up response of verification here for retrieval on next page
        this.storage.update('productDetailsResponse', {...res, serialNumber});
        this.loading = false;
        this.router.navigate(['warranty', 'activation', serialNumber]);
      }, (error) => {
        this.router.navigateByUrl('home');
        this.completed = false;
        this.warranty.serialNumber = '';
        this.loading = false;
      });
    } else {
      this.warranty.serialNumber = '';
      this.loading = false;
      this.completed = false;
    }
  }

  constructor(
    private request: DataService,
    private cookie: CookieService,
    private router: Router,
    private modalService: NgbModal,
    private toast: ToastService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private storage: SessionStorageService,
    private utility: UtilityServiceService
  ) {
    this.route.params.subscribe(params => {
      if (params.authtoken) {
        this.authToken = params.authtoken;
      }
    });
  }

  navOffset() {
    const nav = document.getElementById('home-header');
    if (nav) {
      document.body.style.paddingTop = nav.clientHeight + 'px';
    }
  }

  getWallet(business): Observable<ApiResponse<WalletData[]>> {
    return this.request.getWallet({business}).pipe(tap((resp) => {
      if (resp.status === 200) {
        this.walletDisabled = false;
      } else {
        this.walletDisabled = true;
        throw resp;
      }
    }));
  }

  createWallet(business: number): Observable<ApiResponse<any>> {
    return this.request.createWallet({business}).pipe(tap((response) => {
      return !!(response.data && response.data.id);
    }));
  }

  openHelpDeskModal() { 
    this.dialog.open(HelpDeskModalComponent, {
      panelClass: 'dialog-pannel'
    });
  }


  async ngOnInit() {
    // const dateOLD = localStorage.getItem("last_opened_timestamp")
    const dateOLD =  this.cookie.get('sessionID')
    console.log(dateOLD,'lastOpenedTimestamp')
    const b = moment()
    if (dateOLD) {
      const a = moment(dateOLD)
      if (b.diff(a, 'days') >= 1) {
        this.openHelpDeskModal()
      } else { 
        // localStorage.setItem("last_opened_timestamp", moment().format('MM/DD/YYYY'))
        this.cookie.set('last_opened_timestamp', moment().format('MM/DD/YYYY'), null, '/');
      }
    } else { 
      this.openHelpDeskModal()
      // localStorage.setItem("last_opened_timestamp", moment().format('MM/DD/YYYY'))
      this.cookie.set('last_opened_timestamp', moment().format('MM/DD/YYYY'), null, '/');

    }
    this.storage.remove(['/mapping/foundation/locations', 'profile', 'cachedTimestamp'], true);
    this.utility.widthChanged.subscribe((b) => {
      this.navOffset();
    });
    if (this.authToken) {
      this.router.navigate(['home']);
      this.cookie.set('sessionID', this.authToken, null, '/');
      this.request.kitStatus_no_auth().subscribe(response => {
        this.kitStatus = response.data;
        // this.getSoldKits();
      });
    } else {
      this.request.kitStatus().subscribe(response => {
        this.kitStatus = response.data;
      });
    }
    await this.getvendor();
    this.fetchAndCreateWallet();

  }

  logOut() {
    this.request.logOut().subscribe((response) => {
      if (this.cookie.get('sessionID')) {
        this.cookie.delete('sessionID');
        this.cookie.delete('dashboardUsername');
        this.cookie.delete('role');
        this.cookie.delete('id');
        this.cookie.delete('dashboardUserEmail');
        this.cookie.delete('businessrole');
        this.cookie.delete('business');
        this.storage.remove('profile');
      }
      this.router.navigate(['login']);
    });
  }

  fetchAndCreateWallet() {
    this.getWallet(this.cookie.get('business')).subscribe((dat) => {
      if (dat.data.length === 0) {
        this.createWallet(Number(this.cookie.get('business'))).subscribe((data) => {
          console.log(data);
        }, (err) => {

        });
      }
    }, (err) => {
    });
  }

  showDisabledDialog(disabledDialogContent) {
    this.getWallet(this.cookie.get('business')).subscribe((dat) => {
      if (dat.data.length > 0) {
        this.router.navigateByUrl('warranty/wallet');
      } else {
        this.createWallet(Number(this.cookie.get('business'))).subscribe((data) => {
          console.log(data);
        }, (err) => {

        });
      }
    }, (err) => {
      this.dialog.open(disabledDialogContent, {panelClass: 'dialog-pannel'});
    });
  }

  async getvendor() {
    this.request.getVendor().subscribe(async (res) => {
      if (res) {
        this.cookie.set('businesstype', res.data.result.businesstype, null, '/');
        this.cookie.set('user_type', res.data.result.user_type, null, '/');
        this.cookie.set('business', res.data.result.business, null, '/');
        this.cookie.set('businessrole', res.data.result.businessrole, null, '/');
        this.cookie.set('user_role', res.data.result.user_role, null, '/');
        this.cookie.set('id', res.data.result.id, null, '/');
        this.storage.update('profile', res.data.result);
      }
    });
  }
}
