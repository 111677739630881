<div class="custom-header">
        <!--            <span aria-hidden="true">&times;</span>-->
        <div style="display: flex;justify-content: end;">
            <button mat-icon-button type="button" aria-label="Close" (click)="dialogRef.close()">
                <!--            <span aria-hidden="true">&times;</span>-->
                <mat-icon style="color:gray">close</mat-icon>
            </button>
        </div>

</div>
<mat-dialog-content>
    <div style="display: flex;justify-content: center;align-items: center;">
        <div>
            <div style="display: flex;justify-content: center;align-items: center;">
                <img style="height: 25px;width: auto;" [src]='src' alt="">
            </div>
            <p style="font-weight: 500;" class="mt-1">Support</p>
        </div>
    </div>
    <div style="padding:20px;">
        <p style="text-align: center;font-size: 14px;font-weight:300">For any retailer query or assistance please contact us at <span
                style="color: blue;">+91-9540104398 </span>
            <!-- <br/>from 11am to 7pm, 7days a week. -->
        </p>
    </div>

</mat-dialog-content>