import {Component, Input, OnInit} from '@angular/core';
import {UtilityServiceService} from '../../../assets/services/utility-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private utility: UtilityServiceService) {
  }

  @Input() title = '';
  @Input() to;

  offsetHeader() {
    const nav = document.getElementById('header');
    if (nav) {
      document.body.style.paddingTop = nav.clientHeight + 'px';
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.offsetHeader();
    }, 200);

    this.utility.widthChanged.subscribe((b) => {
      this.offsetHeader();
    });
  }


}
