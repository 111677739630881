import {Component} from '@angular/core';
import {environment} from '../environments/environment';
import {DataService} from '../assets/services/data.service';
import {SessionStorageService} from '../assets/services/session-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'XiaomiWebApp';

  constructor(private request: DataService, private storage: SessionStorageService) {
    this.checkVersion();
  }

  checkVersion() {
    let interval = null;
    // Only active for production mode and if the browser is opened
    if (environment.production && document.getElementById('xiaomiWeb')) {
      interval = setInterval(() => {
        this.request.versionCheck().subscribe((response) => {
          if (response && response.version && response.project === this.title) {
            if (response.version !== environment.version) {
              this.storage.remove(['/mapping/foundation/locations', 'profile', 'cachedTimestamp'], true);
              window.location.reload();
            }
          }
        }, (error) => {
          clearInterval(interval);
        });
      }, 3600000); // I hour interval check 3600000
    } else {
      clearInterval(interval);
    }
  }
}
