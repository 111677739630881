import { Component, OnInit } from '@angular/core';
import { DataService } from "../../assets/services/data.service";
import { ToastService } from "../../assets/services/toast.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  requestObject = {};
  loading: boolean = false;
  otpSent: boolean = false;
  constructor(private request: DataService, private toast: ToastService, private router: Router) { }

  ngOnInit() {
  }
  sendOTP(requestObject) {
    this.loading = true;
    let payload = {
      'username': requestObject['userId'],
      'is_app': 1
    }
    this.request.forgotPassword(payload).subscribe((response) => {
      this.otpSent = true;
      this.loading = false;
      this.toast.showToast({
        type: 'success',
        title: '',
        body: response['message']
      });
    }, (error) => {
      this.otpSent = false;
      this.loading = false;
      this.requestObject = {};
    })
  }
  passwordReset(requestObject) {
    this.loading = true;
    let payload = {
      'username': requestObject['userId'],
      'password': requestObject['password'],
      'code': requestObject['otp'],
      'is_app': 1
    }
    this.request.resetPassword(payload).subscribe((response) => {
      this.loading = false;
      this.toast.showToast({
        type: 'success',
        title: '',
        body: response['message']
      });
      this.router.navigate(["/login"]);
    }, (error) => {
      this.loading = false;
    })
  }
}
