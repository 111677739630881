import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-option-card',
  templateUrl: './dashboard-option-card.component.html',
  styleUrls: ['./dashboard-option-card.component.scss']
})
export class DashboardOptionCardComponent implements OnInit {

  constructor() { }
  @Input()
  src: string;
  @Input()
  title: string;
  @Input()
  to: string;



  ngOnInit() {
  }

}
