import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-desk-modal',
  templateUrl: './help-desk-modal.component.html',
  styleUrls: ['./help-desk-modal.component.sass']
})
export class HelpDeskModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<HelpDeskModalComponent>) { }


  src = '../../../assets/icons/home_support.png'

  ngOnInit(): void {
  }

}
