import {Component, OnInit} from '@angular/core';
import {UtilityServiceService} from '../../assets/services/utility-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private utility: UtilityServiceService) {
  }

  offsetFooter() {
    const nav = document.getElementById('app-footer');
    if (nav) {
      document.body.style.paddingBottom = nav.clientHeight + 'px';
    }
  }

  ngOnInit() {
    this.utility.widthChanged.subscribe((b) => {
      this.offsetFooter();
    });
  }

}
