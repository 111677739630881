import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ApiRequestService} from './api-request.service';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityServiceService {
  token: string;

  constructor() {

  }

  private width: number;


  get widthChanged(): Observable<boolean> {
    let currentWidth = window.outerWidth;
    const bSubject = new BehaviorSubject(false);
    this.windowResized.subscribe((data) => {
      this.width = data.width;
      if (currentWidth !== data.width) {
        bSubject.next(true);
        currentWidth = data.width;
      }
    });
    return bSubject.asObservable();
  }

  get windowResized(): Observable<{ height: number; width: number; }> {
    const bSubject = new BehaviorSubject({height: window.outerHeight, width: window.outerWidth});
    fromEvent(window, 'resize').subscribe((event: any) => {
      bSubject.next({height: event.target.outerHeight, width: event.target.outerWidth});
    });
    return bSubject.asObservable();
  }

  get responsiveQuery():
    {
      isXs?: MediaQueryList,
      isSm?: MediaQueryList,
      isMd?: MediaQueryList,
      isLg?: MediaQueryList,
      isXl?: MediaQueryList,
    } {
    return {
      isXs: matchMedia('(max-width: 575.98px)'),
      isSm: matchMedia('(max-width: 767.98px) and (min-width: 576px)'),
      isMd: matchMedia('(max-width: 991.98px) and (min-width: 768px)'),
      isLg: matchMedia('(max-width: 1199.98px) and (min-width: 992px)'),
      isXl: matchMedia('(min-width: 1200px)'),
    };
  }

  getNewStyleSheet(id: string): CSSStyleSheet {
    // Create the <style> tag
    if (document.getElementById(id)) {
      const styl = (document.getElementById(id) as HTMLStyleElement);
      styl.appendChild(document.createTextNode(''));
      return (styl.sheet as CSSStyleSheet);
    }
    const style = document.createElement('style');
    // WebKit Hack
    style.id = id;
    style.appendChild(document.createTextNode(''));
    // Add the <style> element to the page
    document.head.appendChild(style);
    return (style.sheet as CSSStyleSheet);
  }

}
