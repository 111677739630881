// Use this service for fetching details from Session Storage

import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() {
  }

  protected static projectName: string;
  protected static storageMode: Storage;
  storageKey = 'storageData_' + SessionStorageService.projectName;

  static forRoot(proj, useSessionStorage = false) {
    this.projectName = proj;
    if (useSessionStorage) {
      this.storageMode = sessionStorage;
    } else {
      this.storageMode = localStorage;
    }
    return SessionStorageService;
  }

  protected getData(key: string): any {
    return JSON.parse(SessionStorageService.storageMode.getItem(key));
  }

  protected setData(key: string, data: any): void {
    SessionStorageService.storageMode.setItem(key, JSON.stringify(data));
  }

  update(key: string, value: any): void {
    const store = this.getCompleteStorageData();
    store[key] = value;
    this.setLocalStorageData(store);
  }

  get<T>(key: string): T {
    const store = this.getCompleteStorageData();
    return store[key] as T;
  }

  remove(key: string[] | string, invert = false): void {
    const store = this.getCompleteStorageData();
    const remove = (inver: boolean) => {
      if (typeof key === 'object') {
        if (inver) {
          Object.keys(store).forEach((k) => {
            if (key.indexOf(k) === -1) {
              delete store[k];
            }
          });
        } else {
          key.forEach((k) => {
            delete store[k];
          });
        }

      } else {
        delete store[key];
      }
    };
    remove(invert);
    this.setLocalStorageData(store);
  }

  getCompleteStorageData() {
    return this.getData(this.storageKey) ? this.getData(this.storageKey) : {};
  }

  setLocalStorageData(obj) {
    if (Object.keys(obj).length) {
      console.log('setting local storage data');
      this.setData(this.storageKey, obj);
    }
  }
}
