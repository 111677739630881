<div class="login-page" *ngIf="isShowMsg">
  <div class="logo-wrapper">
    <img class="logo" src="../../assets/images/Xiaomi_Horizontal_Logo Lock.png" />
  </div>
  <div>
    <mat-card class="m-4 text-center">
      <b>HELLO</b>
      <br />
      <b>Sign in to your account</b>
      <div class="form-group">
        <mat-form-field class="w-90">
          <input matInput placeholder="User Id" name="email" [(ngModel)]="user.username" required />
        </mat-form-field>
        <mat-form-field class="w-90">
          <input type="password" matInput placeholder="Please enter password" name="password" [(ngModel)]="user.password" required
          />
        </mat-form-field>
        <div>
          <a routerLink="/reset-password" class="mi-color pull-right">Forgot Password?</a>
        </div>
        <div class="mt-30 text-center">
          <button mat-raised-button class="text-white bg-mi-orange br-30" (click)="login()" [disabled]="!user.username || !user.password">
            LOG IN
          </button>
        </div>

      </div>
    </mat-card>
  </div>

  <ng-template #pageBlocker let-d="dismiss">
    <div class="modal-body page-blocker">
      <mat-card class="m-4 text-center">

        <b>Kindly fill below Details</b>
        <div class="form-group mt-20">
          <mat-form-field class="w-90">
            <input matInput placeholder="Email" #email="ngModel" name="email" pattern=".+@.+..+" [(ngModel)]="updateUser.email" required
            />
          </mat-form-field>
          <div *ngIf="email.touched && email.invalid">
            <p class="error-message pull-left" *ngIf="email.errors?.required">
              Email is required!
            </p>
            <p class="error-message pull-left" *ngIf="email.errors?.pattern">
              Email should be the real one!
            </p>
          </div>
          <mat-form-field class="w-90">
            <input type="number" pattern="^\d{1,}$" [minlength]="10" [maxlength]="10" #phone="ngModel" matInput placeholder="Please enter phone"
              name="phone" [(ngModel)]="updateUser.phone" required />
          </mat-form-field>
          <div *ngIf="phone.touched && phone.invalid">
            <p class="error-message pull-left" *ngIf="phone.errors?.required">
              Contact number is required
            </p>
            <p class="error-message pull-left" *ngIf="phone.errors?.minlength && !phone.errors?.pattern">
              Contact number should be of 10 characters
            </p>
            <p class="error-message pull-left" *ngIf="phone.errors?.pattern">
              Contact number not in valid format
            </p>
          </div>

          <div class="mt-40 text-center">
            <button mat-raised-button class="text-white bg-mi-orange br-30" (click)="userUpdate(updateUser)" [disabled]="!updateUser.email || !updateUser.phone">
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              Submit
            </button>
          </div>
          <div class="p-10 mi-color">
            <a (click)='back()' class="pull-right">Login</a>
          </div>
        </div>
      </mat-card>
    </div>
  </ng-template>
</div>

<div class="login-sms p-4">
  <mat-card class="text-center">Kindly login to Mi smart app for selling Mi Tv Extended warranty</mat-card>
</div>
