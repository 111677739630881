<div class="text-center">
  <nav id="home-header" class="mi-header white mat-elevation-z1 ">
    <div class="logo">
      <img (load)="navOffset()" class="mi_icon" src="../../assets/images/Asset 1@4x.png"/>
    </div>
    <div class="right-person">
      <button mat-button class="person-icon" [matMenuTriggerFor]="menu">
        <i class="fa fa fa-user user_icon"></i>
      </button>
      <mat-menu class="menu" #menu="matMenu">
        <!-- <button class="menu-item" mat-menu-item (click)="opentrainingModal(training)">
  <i class="fa fa-sign-out user_icon"> Training</i>
</button> -->
        <button class="menu-item" mat-menu-item (click)="logOut()">
          <i class="fa fa-sign-out user_icon"> Log Out</i>
        </button>
      </mat-menu>
    </div>
  </nav>
</div>
<div class=" ">
  <div class=" ">
    <ngb-carousel>
      <ng-template ngbSlide>
        <img src="../../assets/images/laptop.jpg" class="carousel-image" alt="Random first slide">
      </ng-template>
      <ng-template ngbSlide>
        <img src="../../assets/images/mobile.jpg" class="carousel-image" alt="Random second slide">
      </ng-template>
      <ng-template ngbSlide>
        <img src="../../assets/images/tv.jpg" class="carousel-image" alt="Random third slide">
      </ng-template>
      <ng-template ngbSlide>
        <img src="../../assets/images/water.jpg" class="carousel-image" alt="Random forth slide">
      </ng-template>
    </ngb-carousel>
  </div>

  <div class="container-fluid mt-20 ">
    <div class="row justify-content-center">
      <div class="col-4 col-md-3">
        <app-dashboard-option-card to="/warranty/past-activation-list" title="History"
                                   src="../../../assets/icons/home_history.png"></app-dashboard-option-card>
      </div>
      <div class="col-4 col-md-3">
        <app-dashboard-option-card
          (click)="showDisabledDialog(disabledWallet)"
           title="Wallet"
          src="../../../assets/icons/wallet_3x.png"></app-dashboard-option-card>
      </div>

      <div class="col-4 col-md-3">

        <app-dashboard-option-card to="/support" title="Support" src="../../../assets/icons/home_support.png">
        </app-dashboard-option-card>
      </div>
    </div>
  </div>

  <div class="px-3 px-md-5 mt-3 mb-3">
    <div class="white sell-card">
      <div class="container-fluid">

        <div class="row">
          <div class="mt-3 d-flex justify-content-center col-12">
            <span>Enter Product Serial No./Mobile IMEI No.</span>
          </div>
          <div class="mt-2 my-2 padding d-flex justify-content-center col-12">
            <input
              [(ngModel)]="warranty['serialNumber']"
              (input)="capitalize($event)"
              name="serialNumber"
              class="form-field" placeholder="Type here..">
          </div>
          <div class="col-12 padding">
            <button
              [disabled]="loading"
              (click)="processSerialNumber()" mat-raised-button class='sell-btn'>
                      <span class="text-center" *ngIf="loading">
                        <i style="font-size:22px" class="fa fa-spinner fa-spin fa-2x"></i>
                      </span>
              <span *ngIf="!loading">Get Plan</span>
            </button>

          </div>

        </div>
      </div>

    </div>

    <div class="mt-3 p-2" style="border:1px solid #e7885c;box-shadow: 0 0 30px #ea966f;border-radius: 7px;">
      <div class="d-flex" style="gap:10px">
        <img height="80px" w="auto" src="../../assets/images/Mi_Cyber_Protect.png" class="icon" alt="Mi_Cyber_Protect">
        <div class="d-flex" style="margin-top: auto;justify-content: end;width: 100%;">
          <div routerLink="/warranty/cyber-plan" style="color: #e9723b;font-size: 14px;margin-top:7px; cursor: pointer;">Get Started &#8594;</div>
        </div>
      </div>
    </div>
    <br/>
    <p style="font-size: 12px;text-align: center;font-weight:300;">For assistance please contact us at <span style="color: blue;">+91-9540104398</span></p>
    <p style="font-size: 12px;text-align: center;font-weight:300;">from 11 am to 8 pm, 7days a week.</p>    
  </div>


  <!-- <div class="mt-20 p-2">
      <div>
          <h4>Recent Activations</h4>
      </div>
      <div class="card mt-20 br-20 fixed-height_and_scroll" *ngIf="soldKitsList.length > 0 && !loading">
          <div class="card m-20 mt-2 mb-2 br-10" *ngFor="let kit of soldKitsList">
              <div class="card-block">
                  <div class="card-body">
                      Customer name : {{ kit.customer.name || "-" }}
                      <br /> Sold on : {{ kit.kit.soldon | date }}
                      <br /> Plan Id : {{ kit.kit.id || "-" }}
                      <br /> Plan Status : {{kitStatus[kit.kit.status] || '-'}}
                      <div *ngIf="(kit['payment']['status'] && kitStatus[kit.kit.status] == 'Pending')" class="text-center p-2">
                          We’ve got your payment and your plan is active! Customer will receive the details within one hour
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card fixed-height_and_scroll" *ngIf="soldKitsList.length == 0 || loading">
          <div class="card-block">
              <div *ngIf="loading" class="text-center mt-5">
                  <i class="fa fa-spinner fa-spin"></i>
              </div>
              <div class="text-center mt-5" *ngIf="soldKitsList.length == 0 && !loading">
                  No records found !
              </div>
          </div>
      </div>
  </div> -->
</div>
<app-footer></app-footer>

<ng-template #training let-d="dismiss">
  <div class="modal-body p-0">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <img class="training_pic" src="https://www.zopper.com/assets/mobile/partners/xiaomi/demo.jpg">
  </div>
</ng-template>

<ng-template #disabledWallet>
  <div class="container-fluid p-3">
    <h5 class="text-center my-2">Wallet Disabled</h5>
    <p class="my-2">Your wallet has been disabled. Please contact your retailer for more information</p>
    <button mat-dialog-close class="my-2 w-100 btn btn-primary">Okay</button>
  </div>
</ng-template>
