import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-credential',
  templateUrl: './invalid-credential.component.html'
})
export class InvalidCredentialComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
