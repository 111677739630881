// export const environment = {
//   product_name: 'mi_web_prod',
//   version: '0.0.0',
//   production: true,
//   BASE_URL: 'https://zopperwarranty-pub.zopper.com:86',
//   URL_MAPPING : {
//     sales: 'https://salesapi.zopper.com',
//     foundation: 'https://apis.zopper.com'
//   },
//   IMAGE_BASE_URL: 'https://apis.zopper.com/media/',
// };
export const environment = {
  product_name: 'mi_web_stg',
  version: '0.0.0',  // kindly change the version in node static API and here
  production: false,
  // 23.22.237.185
  // warranty.stg.zopperext
  BASE_URL: 'https://zopperwarranty-pub.zopper.info',
  URL_MAPPING: {
    sales: '//sales.stg.zopperext',
    foundation: 'https://apis.zopper.info'
  },
  IMAGE_BASE_URL: 'https://apis.zopper.info/media/',
};
