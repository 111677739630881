import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {catchError, map, tap} from "rxjs/operators";
import {CookieService} from "ngx-cookie-service";
import {Observable, of, throwError} from "rxjs";
import {ToastService} from "./toast.service";
import {SessionStorageService} from "./session-storage.service";

@Injectable({
  providedIn: "root"
})
export class ApiRequestService {
  public inSuccessCallback: boolean = false;

  constructor(
    public http: HttpClient,
    public cookie: CookieService,
    public router: Router,
    private storage: SessionStorageService,
    public toast: ToastService
  ) {
  }

  public get(args): Observable<any> {
    this.inSuccessCallback = false;
    const final_url = ApiRequestService.getBaseURL(args["url"]);
    const headers = args["headers"] || {};
    const params = args["params"] || {};
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    const showError = typeof args["showError"] !== "undefined" ? args["showError"] : true;
    const skip_auth = args['skip_auth'] ? args['skip_auth'] : false;
    const options = {headers: headers, params: httpParams};
    if (args["responseType"]) {
      options["responseType"] = args["responseType"];
    }
    if (args["cache"]) {
      if (this.storage.get<any>(args["url"])) {
        if (
          new Date().getTime() -
          parseInt(this.storage.get<any>("cachedTimestamp"), 10) >
          604800000
        ) {
          return this.http.get(final_url, options).pipe(
            tap(data => {
              // localStorage.setItem(args["url"], JSON.stringify(data));
              // localStorage.setItem(
              //   "cachedTimestamp",
              //   String(new Date().getTime())
              // );
              this.storage.update(args["url"], data);
              this.storage.update("cachedTimestamp", new Date().getTime());
              return this.successCallback(data, showError, skip_auth);
            }),
            catchError(data => {
              return this.errorCallback(data, showError, skip_auth);
            })
          );
        } else {
          // return of(JSON.parse(localStorage.getItem(args["url"])));
          return of(this.storage.get<any>(args["url"]));
        }
      } else {
        return this.http.get(final_url, options).pipe(
          tap(data => {
            this.storage.update(args["url"], data);
            this.storage.update("cachedTimestamp", new Date().getTime());
            // localStorage.setItem(args["url"], JSON.stringify(data));
            // localStorage.setItem(
            //   "cachedTimestamp",
            //   String(new Date().getTime())
            // );
            return this.successCallback(data, showError, skip_auth);
          }),
          catchError(data => {
            return this.errorCallback(data, showError, skip_auth);
          })
        );
      }
    } else {
      return this.http.get(final_url, options).pipe(
        tap(data => {
          return this.successCallback(data, showError, skip_auth);
        }),
        catchError(data => {
          return this.errorCallback(data, showError, skip_auth);
        })
      );
    }
  }

  public post(args) {
    this.inSuccessCallback = false;
    const final_url = ApiRequestService.getBaseURL(args["url"]);
    const headers = args["headers"] || {};
    const data = args["data"] || {};
    const showError =
      typeof args["showError"] !== "undefined" ? args["showError"] : true;
    const options = {headers: headers};
    if (args["responseType"]) {
      options["responseType"] = args["responseType"];
    }
    return this.http.post(final_url, data, options).pipe(
      tap(data => this.successCallback(data, showError, null)),
      catchError(data => {
        return this.errorCallback(data, showError, null);
      })
    );
  }

  public put(args) {
    this.inSuccessCallback = false;
    const final_url = ApiRequestService.getBaseURL(args["url"]);
    const headers = args["headers"] || {};
    const data = args["data"] || {};
    const showError =
      typeof args["showError"] !== "undefined" ? args["showError"] : true;
    const options = {headers: headers};
    if (args["responseType"]) {
      options["responseType"] = args["responseType"];
    }
    return this.http.put(final_url, data, options).pipe(
      tap(data => this.successCallback(data, showError, null)),
      catchError(data => {
        return this.errorCallback(data, showError, null);
      })
    );
  }

  public patch(args) {
    this.inSuccessCallback = false;
    const final_url = ApiRequestService.getBaseURL(args["url"]);
    const headers = args["headers"] || {};
    const data = args["data"] || {};
    const showError =
      typeof args["showError"] !== "undefined" ? args["showError"] : true;
    const options = {headers: headers};
    if (args["responseType"]) {
      options["responseType"] = args["responseType"];
    }
    return this.http.patch(final_url, data, options).pipe(
      tap(data => this.successCallback(data, showError, null)),
      catchError(data => {
        return this.errorCallback(data, showError, null);
      })
    );
  }

  private static getBaseURL(in_url) {
    let temp_url;
    if (in_url.indexOf("mapping") > -1) {
      if (in_url.split("/")[2] == "sales") {
        temp_url = environment.URL_MAPPING["sales"];
        in_url = in_url.substring(in_url.indexOf("sales") + "sales".length);
      }
      if (in_url.split("/")[2] == "foundation") {
        temp_url = environment.URL_MAPPING["foundation"];
        in_url = in_url.substring(
          in_url.indexOf("foundation") + "foundation".length
        );
      }
    } else {
      temp_url = environment.BASE_URL;
    }
    return temp_url + in_url;
  }

  private successCallback(data, showError, skip_auth) {
    this.inSuccessCallback = true;
    if (
      data["status"] &&
      typeof data["message"] !== "undefined" &&
      typeof data["data"] !== "undefined"
    ) {
      if (data["status"] === 200 || data["status"] === 202) {
        return of(data);
      } else if (data["status"] === 401) {
        if (skip_auth) {
          this.router.navigate(["/unauthorised"]);
        } else {
          const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Token " + this.cookie.get("sessionID")
          });
          this.post({
            url: "/mapping/foundation/accounts/logout", // foundation
            data: {},
            headers: headers,
            showError: false
          }).subscribe(() => {
            if (this.cookie.get("sessionID")) {
              this.cookie.delete("sessionID");
              this.cookie.delete("dashboardUsername");
              this.cookie.delete("role");
            }

          });
          this.router.navigate(["/login"]);
        }

      } else {
        // if (showError) {
        //   if (data.message) {
        //     this.toast.showToast({
        //       type: "error",
        //       title: "",
        //       body: data.message
        //     });
        //   } else {
        //     this.toast.showToast({
        //       type: "error",
        //       title: "",
        //       body: "Something went wrong"
        //     });
        //   }
        // }
        throw data;
      }
    } else {
      return of(data);
    }
    return data;
  }

  private errorCallback(data, showError, skip_auth) {
    const globalError = 'Oops. There was some problem. Please share screenshot at mi-support@zopper.com';
    console.error(data);
    if (showError) {
      if (data) {
        console.error(data);
        let errorData = "";
        if (data.status && data.status === 0) {
          errorData = 'There seems to be an issue with your internet connectivity. Please try again.';
        } else if (data["status"] && (data["status"] === 500 || data["status"] === 501 || data["status"] === 502) && !errorData) {
          errorData = globalError;  // Server Side Error
        } else if (data.error && data.error.detail && !errorData) {
          errorData = data.error.detail;
        }
        else if (data.message && !errorData) {
          errorData = data.message;
        } else if (data.error && data.error.exception && !errorData) {
          errorData = data.error.exception;
        } else if (data.data && data.data.exception && !errorData) {
          errorData = data.data.exception;
        } else {
          errorData = globalError;
        }
        console.log({
          type: "error",
          title: "",
          body: errorData
        })
        this.toast.showToast({
          type: "error",
          title: "",
          body: errorData
        });
      } else {
        this.toast.showToast({
          type: "error",
          title: "",
          body: "Something went wrong"
        });
      }
    }
    if (data["status"] === 401) {
      if (skip_auth) {
        this.router.navigate(["/unauthorised"]);
      } else {
        if (this.cookie.get("sessionID")) {
          this.cookie.delete("sessionID");
          this.cookie.delete("username");
          this.cookie.delete("userRole");
        }
        this.router.navigate(["/login"]);
      }
    }
    return throwError(data);
  }
}
