import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { LoginActivateGuard } from "../assets/guards/login-activate.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InvalidCredentialComponent } from './invalid-credential/invalid-credential.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [LoginActivateGuard]
  },
  {
    path: 'ssotoken/:authtoken',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'unauthorised',
    component: InvalidCredentialComponent,
  },
  {
    path: 'warranty',
    loadChildren: () => import('./warranty/warranty.module').then(m => m.WarrantyModule),
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,

  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    canActivate: [LoginActivateGuard]
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
