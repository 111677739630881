<div class="request-password" *ngIf="!otpSent">
  <mat-card class="m-4 text-center">
    <b>SEND OTP</b>
    <br />
    <b class="mi-color">An OTP will be send to registered mail id</b>
    <div class="form-group mt-20">
      <mat-form-field class="w-90">
        <input matInput placeholder="User Id" name="Enter user id" [(ngModel)]="requestObject.userId" required />
      </mat-form-field>
      <div class="mt-20 text-center">
        <button mat-raised-button class="text-white bg-mi-orange br-30" [disabled]="!requestObject.userId" (click)="sendOTP(requestObject)">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          Send OTP
        </button>
      </div>
    </div>
  </mat-card>

</div>

<div class="reset-password" *ngIf="otpSent">
  <mat-card class="m-4 text-center">

    <b>
      RESET PASSWORD</b>


    <br />
    <b class="mi-color">OTP has been sent to the registered mobile, please enter OTP and password below.</b>
    <div class="form-group mt-20">
      <mat-form-field class="w-90">
        <input matInput placeholder="User Id" name="email" [(ngModel)]="requestObject.userId" required />
      </mat-form-field>
      <mat-form-field class="w-90">
        <input type="password" #password="ngModel" matInput placeholder="Password" name="password" [(ngModel)]="requestObject.password"
          required />
      </mat-form-field>
      <mat-form-field class="w-90">
        <input type="password" matInput #cnfpassword="ngModel" placeholder="Confirm Password" name="cnfpassword" [(ngModel)]="requestObject.cnfpassword"
          required />
      </mat-form-field>
      <div *ngIf="password.touched && cnfpassword.touched">
        <p class="error-message pull-left m-20" *ngIf="requestObject.password != requestObject.cnfpassword">
          Password does not match !
        </p>
      </div>
      <mat-form-field class="w-90">
        <input type="number" matInput placeholder="OTP Code" name="number" [(ngModel)]="requestObject.otp" required />
      </mat-form-field>

      <div class="mt-20 text-center">
        <button mat-raised-button class="text-white bg-mi-orange br-30" (click)="passwordReset(requestObject)">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          Reset
        </button>
      </div>
    </div>
  </mat-card>

</div>