import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ApiRequestService} from './api-request.service';
import {map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse, FetchUserResponse, WalletData, WalletRecharge, WalletTransactionParams} from '../interfaces/general';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  token: string;

  constructor(
    private apiRequest: ApiRequestService,
    private cookie: CookieService
  ) {
    this.token = this.cookie.get('sessionID');
  }


  createWallet(data: {
    user?: number;
    business?: number;
    force?: number;
  }): Observable<ApiResponse<{ id: number }>> {
    const endpoint = '/mapping/foundation/wallet/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: endpoint, // foundation
      headers,
      data,
      showError: false
    }) as Observable<ApiResponse<{ id: number }>>;
  }

  updateWallet(data: { walletId: number }): Observable<ApiResponse<any>> {
    const endpoint = '/mapping/foundation/wallet/' + data.walletId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: endpoint, // foundation
      headers,
      data,
      showError: false
    }) as Observable<ApiResponse<any>>;
  }

  getWallet(data: {
    business?: number;
    user?: number;
  }): Observable<ApiResponse<WalletData[]>> {
    const endpoint = '/mapping/foundation/wallet/wallets';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: endpoint,
      headers,
      params: data,
      showError: false
    });

  }

  walletRecharge(body: {
    amount: number;
    walletid?: number;
    flag: 'user' | 'business';
  }): Observable<ApiResponse<WalletRecharge>> {
    const endpoint = '/mapping/foundation/wallet/recharge';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: endpoint, // foundation
      headers,
      data: body,
    }) as Observable<ApiResponse<WalletRecharge>>;
  }

  getUsers(data: { filter: any } | any): Observable<ApiResponse<FetchUserResponse>> {
    const endpoint = '/mapping/foundation/accounts/users';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: endpoint,
      headers,
      params: data
    }) as Observable<ApiResponse<FetchUserResponse>>;

  }

  sendMoney(body: { amount: number, wallet_id: number }): Observable<ApiResponse<any>> {
    const endpoint = '/mapping/foundation/wallet/payout';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: endpoint, // foundation
      headers,
      data: body,
      showError: false
    }) as Observable<ApiResponse<any>>;
  }


  getWalletTransactions(data: { walletId: number }, params: WalletTransactionParams = {}): Observable<ApiResponse<any[]>> {
    const endpoint = `/mapping/foundation/wallet/${data.walletId}/transactions`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: endpoint,
      headers,
      showError: false,
      params
    });
  }

  isAuthenticated() {
    return this.cookie.get('sessionID');
  }

  formatDate(date) {
    let month = date.month.toString(),
      day = date.day.toString(),
      year = date.year.toString();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  formatDateObject(date: Date) {
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    const year = date.getFullYear().toString();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  login(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.apiRequest.post({
      url: '/mapping/foundation/accounts/login', // foundation
      data,
      headers,
    });
  }

  fetchPlansFromUserTypeAndCategory(params: any) {
    const endpoint = '/mapping/foundation/accounts/plantype/listing';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: endpoint,
      headers,
      params
    });
  }

  logOut() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: '/mapping/foundation/accounts/logout', // foundation
      headers,
      showError: false
    });
  }

  sendOtp(body: { email?: string; phone?: string }): Observable<ApiResponse<any>> {
    const endpoint = '/mapping/foundation/otp/send';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: endpoint, // foundation
      headers,
      data: body,
    }) as Observable<ApiResponse<any>>;
  }

  verifyOtp(body: { otp: string; email?: string; phone?: string }): Observable<ApiResponse<any>> {
    const endpoint = '/mapping/foundation/otp/verify';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: endpoint, // foundation
      headers,
      data: body,
    }) as Observable<ApiResponse<any>>;
  }

  updateUser(id, payload: { wallet_active?: boolean } | any): Observable<ApiResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.patch({
      url: '/mapping/foundation/accounts/user/' + id,
      headers,
      data: payload
    }) as Observable<ApiResponse<any>>;
  }

  catalogCategories(param) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/mapping/foundation/catalog/categories',
      headers,
      params: param
    });
  }

  forgotPassword(payload) {
    return this.apiRequest.post({
      url: '/mapping/foundation/accounts/forgotpassword',
      data: payload
    });
  }

  resetPassword(payload) {
    return this.apiRequest.post({
      url: '/mapping/foundation/accounts/resetpassword',
      data: payload
    });
  }

  kitStatus() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/noauth/kitstatuses',
      headers,
    });
  }

  kitStatus_no_auth() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/noauth/kitstatuses',
      headers,
      skip_auth: true
    });
  }

  getPremium(param) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/premium',
      headers,
      params: param
    });
  }

  getWarrantyTypes(params): Observable<any> {
    const endpoint = '/zopperassure/noauth/warrantytypes';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: endpoint,
      headers,
      params
    });
  }

  mediaUpload(key, param) {
    const headers = new HttpHeaders({
      Authorization: 'Token ' + this.cookie.get('sessionID'),
    });
    if (key) {
      return this.apiRequest.patch({
        url: '/mapping/foundation/media/' + key,
        headers,
        data: param
      });
    } else {
      return this.apiRequest.post({
        url: '/mapping/foundation/media',
        headers,
        data: param
      });
    }
  }

  stateCityListing() {
    return this.apiRequest.get({
      url: '/mapping/foundation/locations',
      cache: true
    });
  }

  stateCityMapping() {
    const mapping = {
      stateCityMapping: {},
      stateList: {},
      cityList: {},
    };
    // return this.stateCityListing().pipe(map((response) => {
    //   mapping.stateCityMapping = response.data;
    //   for (const index in response.data) {
    //     if (response.data[index]) {
    //       mapping.stateList[index] = response.data[index].name;
    //       for (const i in response.data[index].cities) {
    //         if (response.data[index].cities[i]) {
    //           mapping.cityList[i] = response.data[index].cities[i].name;
    //         }
    //       }
    //     }
    //   }
    //   return mapping;
    // }));
  }

  getSoldKits(param: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/kits',
      params: param,
      headers
    });
  }

  getKitDetail(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/kit/' + id,
      headers
    });
  }
  getHomeassureKitDetail(id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/homeassure/kit',
      headers,
      params: {
        id:id
      }
    });
  }

  kitSubmission(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: '/zopperassure/kit',
      headers,
      data: payload
    });
  }
  cyberKitSubmission(payload) { 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: '/homeassure/kit',
      headers,
      data: payload
    });
  }

  kitUpdate(id, payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.patch({
      url: '/zopperassure/kit/' + id,
      headers,
      data: payload
    });
  }

  kitActivate(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: '/zopperassure/kit/self/activate',
      headers,
      data: payload
    });
  }

  fetchOtherDocuments(param): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopper/documents',
      params: param,
      headers
    });
  }


  storePerformanceSummary(param) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/user/performance/summary?id=' + this.cookie.get('id'),
      params: param,
      headers
    });
  }

  storePerformanceTrend(params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/store/performance/trend',
      params,
      headers
    });
  }

  getProductName(param) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/partner/serialnumber/check',
      params: param,
      headers
    });
  }

  paymentPay(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: '/zopperassure/pay',
      headers,
      data: payload
    });
  }

  paymentStatus(param) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/kit/paymentstatus',
      params: param,
      headers
    });
  }

  getVendor() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/kit/is_vendor',
      headers,
      showError: true
    });
  }

  versionCheck() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.apiRequest.get({
      url: '/mapping/foundation/staticapis/XiaomiWebApp', // foundation
      headers,
    });
  }

  supportRequest(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.post({
      url: '/zopperassure/customer-helpline',
      headers,
      data: payload
    });
  }

  supportStatusList() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.cookie.get('sessionID')
    });
    return this.apiRequest.get({
      url: '/zopperassure/customer-helpline/listing',
      headers,
    });
  }


  getRetailerInvoiceDownload(id) {
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/pdf',
      Authorization: 'Token ' + this.cookie.get('sessionID')
      // Authorization: 'Token 0188a14624fbb79e3268738c9d2bbb6ec5a4d3a3',

    });
    return this.apiRequest.get({
      url: `/zopper/finance/download-invoice?warranty_id=${id}&is_media=true`,
      headers,
      cache: false
      // responseType:'text'
    });
  }
}
