import {Injectable} from '@angular/core';
import {
  ToasterService,
  ToasterConfig,
  Toast,
  BodyOutputType
} from 'angular2-toaster';
import {ToastrService} from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  config: ToasterConfig;

  constructor(private toasterService: ToasterService, private toastr: ToastrService) {
  }

  showToast(data: {
    type: 'success' | 'error' | 'warning' | 'info',
    title?: string,
    body?: string,
  }) {
    switch (data.type) {
      case "error":
        this.toastr.error(data.body, data.title);
        break;
      case "success":
        this.toastr.success(data.body, data.title);
        break;
      case "info":
        this.toastr.info(data.body, data.title);
        break;
      case "warning":
        this.toastr.warning(data.body, data.title);
        break;
    }
    // this.toastr.success()
    // this.config = new ToasterConfig({
    //   positionClass: 'toast-top-right',
    //   timeout: 5000,
    //   newestOnTop: true,
    //   tapToDismiss: true,
    //   preventDuplicates: false,
    //   animation: 'fade',
    //   limit: 5
    // });
    // const toast: Toast = {
    //   type: data.type,
    //   title: data.title ? data.title : '',
    //   body: data.body ? data.body : '',
    //   timeout: 5000,
    //   showCloseButton: true,
    //   bodyOutputType: BodyOutputType.TrustedHtml
    // };
    // this.toasterService.popAsync(toast);
  }
}
